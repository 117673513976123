import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./InvestmentMastery.css";
import Investmentimgurl from "../../../assets/ir-1.png";
import { useInView } from "react-intersection-observer";

const InvestmentMastery = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const [isMobile, setIsMobile] = useState(false);

  // Check if screen width is mobile-sized
  useEffect(() => {
    const checkMobile = () => {
      return window.innerWidth <= 768;
    };

    // Set initial state
    setIsMobile(checkMobile());

    // Add resize listener
    const handleResize = () => setIsMobile(checkMobile());
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Animation control based on view and device type
  useEffect(() => {
    if (inView && !isMobile) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    } else if (isMobile) {
      // Immediately set to final state without animation on mobile
      controls.set({
        opacity: 1,
        y: 0,
      });
    }
  }, [controls, inView, isMobile]);

  // Conditional rendering based on device type
  if (isMobile) {
    return (
      <section className="investment-mastery">
        <div className="container-fluid">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-5 image-container-section">
              <div className="image-wrapper">
                <img
                  src={Investmentimgurl}
                  alt="Investment Discussion"
                  className="img-fluid"
                />
                <div className="image-overlay">
                  <h2 style={{ marginTop: "-40px" }}>
                    INVESTMENT
                    <br />
                    MASTERY
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-7 text-container">
              <div className="text-wrapper">
                <p className="subtitle text-white text-left">
                  For over two decades, Mr Rasmussen worked in trading and
                  investing the capital and enhancing the returns of several
                  large reputable businesses and balance sheets within the EU,
                  having grown and managed trading, treasury, venture and
                  investment businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Desktop version with animations
  return (
    <motion.section
      className="investment-mastery"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
    >
      <div className="container-fluid">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-md-5 image-container-section">
            <div className="image-wrapper">
              <img
                src={Investmentimgurl}
                alt="Investment Discussion"
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
              />
              <div className="image-overlay">
                <h2
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-sine"
                  style={{ marginTop: "-40px" }}
                >
                  INVESTMENT
                  <br />
                  MASTERY
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-7 text-container">
            <div className="text-wrapper">
              <p
                className="subtitle text-white text-left"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
              >
                For over two decades, Mr Rasmussen worked in trading and
                investing the capital and enhancing the returns of several large
                reputable businesses and balance sheets within the EU, having
                grown and managed trading, treasury, venture and investment
                businesses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default InvestmentMastery;
