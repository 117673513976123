import React from "react";
import { Breadcrumb } from "react-bootstrap";

const PagesBreadcrums = ({ items  }) => {
  return (
    <div className="container mt-0 pl-0">
      <Breadcrumb
        className="menu-breadcrumb-items d-flex align-items-center"
        style={{ marginTop: "0rem" }}
      >
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <Breadcrumb.Item
              className={`breadcrumb-link px-2 py-1  border-dashed ${item.active ? "text-primary" : "text-dark"}`}
              href={!item.active ? item.href : undefined}
              active={item.active}
            >
              {item.label}
            </Breadcrumb.Item>
            {index !== items.length - 1 && <span className="mx-0"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 9 17" fill="none">
  <path d="M9.00003 8.24997C9.00003 8.44178 8.92672 8.63378 8.78028 8.78021L1.28029 16.2802C0.987232 16.5733 0.512671 16.5733 0.219797 16.2802C-0.073078 15.9871 -0.0732655 15.5126 0.219796 15.2197L7.18954 8.24997L0.219796 1.28023C-0.0732662 0.987165 -0.0732662 0.512602 0.219796 0.219727C0.512858 -0.0731468 0.987419 -0.0733357 1.28029 0.219727L8.78028 7.71972C8.92672 7.86615 9.00003 8.05815 9.00003 8.24997Z" fill="black"/>
</svg></span>}
          </React.Fragment>
        ))}
      </Breadcrumb>

   
    </div>
  );
};

export default PagesBreadcrums;
