// "use client"

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import XIcon from '@mui/icons-material/X';
// import { useLocation, useNavigate } from "react-router-dom";
// import PagesBreadcrums from "components/Breadcrums/PagesBreadcrums";
// import { BASE_URL } from "APIS/constatnts";

// const BlogDetail = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const article = location.state?.article || {};
//     const [relatedNews, setRelatedNews] = useState([]);

//   useEffect(() => {
//     const fetchRelatedNews = async () => {
//         if (!article?.category_name) return;

//         try {
//             const response = await axios.get(`${BASE_URL}news-list`);
//             console.log("API Response:", response.data); // Debug log

//             const newsData = response.data?.data?.data || []; // Ensure it's an array

//             const filteredNews = newsData
//                 .filter(news => 
//                     news.id !== article.id && 
//                     news.category_name === article.category_name // Filter by category
//                 )
//                 .slice(0, 3);

//             setRelatedNews(filteredNews);
//         } catch (error) {
//             console.error("Error fetching related news:", error);
//             setRelatedNews([]);
//         }
//     };

//     fetchRelatedNews();
// }, [article?.id, article?.category_name]); // Re-fetch when article ID or category changes


//     console.log('relatedNews', relatedNews);
    

//     const handleReadMore = (newsItem) => {
//       navigate(`/blog-detail/${newsItem.id}`, { 
//           state: { 
//               article: newsItem 
//           } 
//       });
//       window.scrollTo(0, 0);
//   };

//     if (!article?.title) {
//         return <div className="max-w-[1350px] mx-auto px-5 py-8">Article not found</div>;
//     }

//     return (
//         <div className="mx-auto  main-section-blog-detail">
//             <PagesBreadcrums
//                 items={[
//                     { label: "HOME", href: "/" },
//                     { label: "NEWS & INSIGHTS", href: "/news-insights" },
//                     { label: article.title, active: true },
//                 ]}
//             />

//             <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
//                 <div className="lg:col-span-2">
//                     <h2 className="text-4xl font-serif text-[#1e3a8a] mb-6 uppercase blog-detail-heading">
//                         {article.title}
//                     </h2>

//                     <div className="flex items-center justify-between border-b border-gray-200 pb-4 blog-date-boxes">
//                         <div className="text-gray-500 date-p">
                            
                            
//                             <p>Published as on {article.news_date}  |  { article.category_name} </p>
//                              {/* <p> | { article.category_name}</p> */}



//                         </div>
//                        <div className="flex items-center gap-4">
//     <span className="text-gray-500 date-p">Share By</span>
//     <div className="flex gap-3">
//         <FacebookIcon 
//             className="text-blue-600 hover:opacity-80 cursor-pointer"
//             onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, "_blank")}
//         />
//         <InstagramIcon 
//             className="text-blue-600 hover:opacity-80 cursor-pointer"
//             onClick={() => window.open(`https://www.instagram.com/`, "_blank")}
//         />
//         <XIcon 
//             className="text-blue-600 hover:opacity-80 cursor-pointer"
//             onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}&text=${encodeURIComponent(article.title)}`, "_blank")}
//         />
//         <LinkedInIcon 
//             className="text-blue-600 hover:opacity-80 cursor-pointer"
//             onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`, "_blank")}
//         />
//     </div>
// </div>

//                     </div>

//                     <div className="mb-8">
//                         <img
//                             src={article.image}
//                             alt={article.title || "Article"}
//                             className="w-full blog-image-detail"
//                         />
//                     </div>

//                     <div className="space-y-6 text-gray-700">
//                         {/* <p className="text-gray-700 mb-6 leading-relaxed max-w-4xl">
//                             {article.short_description}
//                         </p> */}
//                         <div className="log-decription">
//     <p className="leading-relaxed" 
//        dangerouslySetInnerHTML={{ __html: article.long_description }} 
//                                 style={{ overflow: "hidden" }}></p>
                            


//                         </div>
                        
//                         <div className="linkdeen-box">
//                            <h2 className="text-linkdin">To get more insights & to stay updated, follow us on</h2>
//                            <a href="https://www.linkedin.com/company/dovehouse-capital/?originalSubdomain=ae" target="_blank"  style={{cursor:'pointer'}}><img src="/images/icons/LinkedIn_icon.svg" /> </a>
//                         </div>

//                     </div>
//                 </div>

//                 {/* Right Sidebar - Related Articles */}
//                 <div className="lg:col-span-1 fixed-sideber">
//                     <div className="space-y-8">
//                         <h1 className="text-2xl font-serif mb-4 uppercase tracking-wide">Related News & INSIGHTS</h1>

//                         {relatedNews.map((news) => (
//                             <div key={news.id || Math.random()} className="bg-white overflow-hidden shadow-md">
//                                 <img
//                                     src={news.image}
//                                     alt={news.title || "Related article"}
//                                     className="w-full h-48 object-cover cursor-pointer"
//                                     onClick={() => handleReadMore(news)}
//                                 />
//                                 <div className="p-6">
//                                     <div className="text-sky-500 text-sm mb-2 date-box">
                                        
//                                         {news.news_date}

//                                         <p>{ news.category_name}</p>



//                                     </div>
//                                     <h3 className="font-serif blog-news-title text-xl mb-3 cursor-pointer hover:text-sky-500"
//                                         onClick={() => handleReadMore(news)}>
//                                         {news.title}
//                                     </h3>
//                                     <p className="text-gray-600 mb-4 line-clamp-3" 
//                                        dangerouslySetInnerHTML={{ __html: news.short_description }}>
//                                     </p>
//                                     <button 
//                                         onClick={() => handleReadMore(news)}
//                                         className="btn-blogs text-sky-500 border border-sky-500 px-4 py-2 hover:bg-sky-100 transition-colors">
//                                         READ MORE
//                                     </button>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default BlogDetail;



// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams, useNavigate } from "react-router-dom";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import XIcon from '@mui/icons-material/X';
// import PagesBreadcrums from "components/Breadcrums/PagesBreadcrums";
// import { BASE_URL } from "APIS/constatnts";

// const BlogDetail = () => {
//     const { id } = useParams(); // Extract blog ID from URL
//     const navigate = useNavigate();
//     const [article, setArticle] = useState(null);
//     const [relatedNews, setRelatedNews] = useState([]);

//     useEffect(() => {
//         const fetchNewsList = async () => {
//             try {
//                 const response = await axios.get(`${BASE_URL}news-list`);
//                 const newsList = response.data?.data?.data || [];

//                 // Find the article with the matching ID
//                 const selectedArticle = newsList.find(news => news.id === parseInt(id));

//                 if (selectedArticle) {
//                     setArticle(selectedArticle);

//                     // Filter related news articles
//                     const related = newsList
//                         .filter(news => news.id !== selectedArticle.id && news.category_name === selectedArticle.category_name)
//                         .slice(0, 3);

//                     setRelatedNews(related);
//                 } else {
//                     setArticle(null);
//                 }
//             } catch (error) {
//                 console.error("Error fetching news list:", error);
//                 setArticle(null);
//             }
//         };

//         if (id) {
//             fetchNewsList();
//         }
//     }, [id]);

// const handleReadMore = (newsItem) => {
//     navigate(`/news-insights-detail/${newsItem.id}`);
//     setTimeout(() => {
//         window.scrollTo({ top: 0, behavior: "smooth" });
//         window.location.reload(); // Reload the page
//     }, 50);
// };


//     const shareUrl = window.location.href; // Get the current page URL

//     if (!article) {
//         return <div className="max-w-[1350px] mx-auto px-5 py-8">Loading...</div>;
//     }

//     return (
//         <div className="mx-auto main-section-blog-detail">
//             <PagesBreadcrums
//                 items={[
//                     { label: "HOME", href: "/" },
//                     { label: "NEWS & INSIGHTS", href: "/news-insights" },
//                     { label: article.title, active: true },
//                 ]}
//             />

//             <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
//                 <div className="lg:col-span-2">
//                     <h2 className="text-4xl font-serif text-[#1e3a8a] mb-6 uppercase blog-detail-heading">
//                         {article.title}
//                     </h2>

//                     <div className="flex items-center justify-between border-b border-gray-200 pb-4 blog-date-boxes">
//                         <div className="text-gray-500 date-p">
//                             <p>Published as on {article.news_date} | {article.category_name}</p>
//                         </div>
//                         <div className="flex items-center gap-4">
//                             <span className="text-gray-500 date-p">Share By</span>
//                             <div className="flex gap-3">
//                                 <FacebookIcon 
//                                     className="text-blue-600 hover:opacity-80 cursor-pointer"
//                                     onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, "_blank")}
//                                 />
//                                 <InstagramIcon 
//                                     className="text-blue-600 hover:opacity-80 cursor-pointer"
//                                     onClick={() => window.open(`https://www.instagram.com/`, "_blank")}
//                                 />
//                                 <XIcon 
//                                     className="text-blue-600 hover:opacity-80 cursor-pointer"
//                                     onClick={() => window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(article.title)}`, "_blank")}
//                                 />
//                                 <LinkedInIcon 
//                                     className="text-blue-600 hover:opacity-80 cursor-pointer"
//                                     onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, "_blank")}
//                                 />
//                             </div>
//                         </div>
//                     </div>

//                     <div className="mb-8">
//                         <img src={article.image} alt={article.title} className="w-full blog-image-detail" />
//                     </div>

//                     <div className="space-y-6 text-gray-700">
//                         <div className="log-decription">
//                             <p className="leading-relaxed" 
//                                dangerouslySetInnerHTML={{ __html: article.long_description }} 
//                                style={{ overflow: "hidden" }}>
//                             </p>
//                         </div>

//                         <div className="linkdeen-box">
//                             <h2 className="text-linkdin">To get more insights & to stay updated, follow us on</h2>
//                             <a href="https://www.linkedin.com/company/dovehouse-capital/?originalSubdomain=ae" target="_blank" style={{cursor:'pointer'}}>
//                                 <img src="/images/icons/LinkedIn_icon.svg" alt="LinkedIn"/>
//                             </a>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Right Sidebar - Related Articles */}
//                 <div className="lg:col-span-1 fixed-sideber" >
//                     <div className="space-y-8">
//                         <h1 className="text-2xl font-serif mb-4 uppercase tracking-wide">Related News & INSIGHTS</h1>

//                         {relatedNews.map((news) => (
//                             <div key={news.id || Math.random()} className="bg-white overflow-hidden    shadow-md card-blogs "  onClick={() => handleReadMore(news)} >
//                                 <img 
//                                     src={news.image} 
//                                     alt={news.title} 
//                                     className="w-full h-48 object-cover cursor-pointer"
//                                     onClick={() => handleReadMore(news)}
//                                 />
//                                 <div className="p-6">
//                                     <div className="text-sky-500 text-sm mb-2 date-box">
//                                         {news.news_date}
//                                         <p>{news.category_name}</p>
//                                     </div>
//                                     <h3 className="font-serif blog-heading  text-xl mb-3 cursor-pointer hover:text-sky-500"
//                                         onClick={() => handleReadMore(news)}>
//                                         {news.title}
//                                     </h3>
//                                     <p className="text-gray-600 mb-4 line-clamp-3" 
//                                        dangerouslySetInnerHTML={{ __html: news.short_description }}>
//                                     </p>
//                                     <button 
//                                         onClick={() => handleReadMore(news)}
//                                         className="btn-blogs text-sky-500 border border-sky-500 px-4 py-2 hover:bg-sky-100 transition-colors">
//                                         READ MORE
//                                     </button>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default BlogDetail;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import PagesBreadcrums from "components/Breadcrums/PagesBreadcrums";
import { BASE_URL } from "APIS/constatnts";

// Utility function to create slug
const createSlug = (title) => {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-|-$/g, '')
        .substring(0, 50); // Limit slug length
};

const BlogDetail = () => {
    const { slug } = useParams(); // Extract blog slug from URL
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const [relatedNews, setRelatedNews] = useState([]);

    useEffect(() => {
        const fetchNewsList = async () => {
            try {
                const response = await axios.get(`${BASE_URL}news-list`);
                const newsList = response.data?.data?.data || [];

                // Find the article with the matching slug
                const selectedArticle = newsList.find(news => 
                    createSlug(news.title) === slug
                );

                if (selectedArticle) {
                    setArticle(selectedArticle);

                    // Filter related news articles
                    const related = newsList
                        .filter(news => 
                            news.id !== selectedArticle.id && 
                            news.category_name === selectedArticle.category_name
                        )
                        .slice(0, 3);

                    setRelatedNews(related);
                } else {
                    // Fallback: try to find by ID if slug matching fails
                    const fallbackArticle = newsList.find(news => 
                        news.id === parseInt(slug)
                    );
                    
                    if (fallbackArticle) {
                        setArticle(fallbackArticle);
                        
                        const related = newsList
                            .filter(news => 
                                news.id !== fallbackArticle.id && 
                                news.category_name === fallbackArticle.category_name
                            )
                            .slice(0, 3);

                        setRelatedNews(related);
                    } else {
                        setArticle(null);
                    }
                }
            } catch (error) {
                console.error("Error fetching news list:", error);
                setArticle(null);
            }
        };

        if (slug) {
            fetchNewsList();
        }
    }, [slug]);

    const handleReadMore = (newsItem) => {
        // Update to use slug instead of ID
        const newsSlug = createSlug(newsItem.title);
        navigate(`/${newsSlug}`);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            window.location.reload(); // Reload the page
        }, 50);
    };

    const shareUrl = window.location.href; // Get the current page URL

    if (!article) {
        return <div className="max-w-[1350px] mx-auto px-5 py-8">Loading...</div>;
    }

    return (
        <div className="mx-auto main-section-blog-detail">
            <PagesBreadcrums
                items={[
                    { label: "HOME", href: "/" },
                    { label: "NEWS & INSIGHTS", href: "/news-insights" },
                    { label: article.title, active: true },
                ]}
            />

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2">
                    <h2 className="text-4xl font-serif text-[#1e3a8a] mb-6 uppercase blog-detail-heading">
                        {article.title}
                    </h2>

                    <div className="flex items-center justify-between border-b border-gray-200 pb-4 blog-date-boxes">
                        <div className="text-gray-500 date-p">
                            <p>Published as on {article.news_date} | {article.category_name}</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <span className="text-gray-500 date-p">Share By</span>
                            <div className="flex gap-3">
                                <FacebookIcon 
                                    className="text-blue-600 hover:opacity-80 cursor-pointer"
                                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, "_blank")}
                                />
                                <InstagramIcon 
                                    className="text-blue-600 hover:opacity-80 cursor-pointer"
                                    onClick={() => window.open(`https://www.instagram.com/`, "_blank")}
                                />
                                <XIcon 
                                    className="text-blue-600 hover:opacity-80 cursor-pointer"
                                    onClick={() => window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(article.title)}`, "_blank")}
                                />
                                <LinkedInIcon 
                                    className="text-blue-600 hover:opacity-80 cursor-pointer"
                                    onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`, "_blank")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-8">
                        <img src={article.image} alt={article.title} className="w-full blog-image-detail" />
                    </div>

                    <div className="space-y-6 text-gray-700">
                        <div className="log-decription">
                            <p className="leading-relaxed" 
                               dangerouslySetInnerHTML={{ __html: article.long_description }} 
                               style={{ overflow: "hidden" }}>
                            </p>
                        </div>

                        <div className="linkdeen-box">
                            <h2 className="text-linkdin">To get more insights & to stay updated, follow us on</h2>
                            <a href="https://www.linkedin.com/company/dovehouse-capital/?originalSubdomain=ae" target="_blank" rel="noopener noreferrer" style={{cursor:'pointer'}}>
                                <img src="/images/icons/LinkedIn_icon.svg" alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Right Sidebar - Related Articles */}
                <div className="lg:col-span-1 fixed-sideber" >
                    <div className="space-y-8">
                        <h1 className="text-2xl font-serif mb-4 uppercase tracking-wide">Related News & INSIGHTS</h1>

                        {relatedNews.map((news) => (
                            <div key={news.id || Math.random()} className="bg-white overflow-hidden shadow-md card-blogs"  onClick={() => handleReadMore(news)} >
                                <img 
                                    src={news.image} 
                                    alt={news.title} 
                                    className="w-full h-48 object-cover cursor-pointer"
                                    onClick={() => handleReadMore(news)}
                                />
                                <div className="p-6">
                                    <div className="text-sky-500 text-sm mb-2 date-box">
                                        {news.news_date}
                                        <p>{news.category_name}</p>
                                    </div>
                                    <h3 className="font-serif blog-heading text-xl mb-3 cursor-pointer hover:text-sky-500"
                                        onClick={() => handleReadMore(news)}>
                                        {news.title}
                                    </h3>
                                    <p className="text-gray-600 mb-4 line-clamp-3" 
                                       dangerouslySetInnerHTML={{ __html: news.short_description }}>
                                    </p>
                                    <button 
                                        onClick={() => handleReadMore(news)}
                                        className="btn-blogs text-sky-500 border border-sky-500 px-4 py-2 hover:bg-sky-100 transition-colors">
                                        READ MORE
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetail;
export { createSlug }; // Export the slug creation utility
