import React from 'react'
import { motion } from "framer-motion";

const ValueProposition = () => {
  return (
     <div>
    <div className="section-titles">
      <motion.h2
        style={{ paddingBottom: "20px",paddingTop:'20px' }}
        className="section-title"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >Value  Proposition  </motion.h2>
    </div>
    <div className="container-section-value" style={{backgroundColor:'#EFF9FD',paddingBottom:'5rem'}}>
    


      <div className='conatiner'>
      <motion.h2
        style={{ paddingBottom: "20px" }}
        className="section-title-value-propotion"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      > An Absolute Focus on Consistent  & <br className="br-none" />
      <span className="highlight-value">Compounding Growth</span> </motion.h2>
      </div>
    <div className='container value-section' >

      <div className='row p-0' style={{alignItems:'center'}}>
        <div className='col-lg-6 p-0'>

        <img src='/images/face-img-value-prop.png' className='img-fuild'/>
        </div>
        <div className='col-lg-6 p-5 boxes-values'>

          <div className='about-value-prepotion'>
           <div className='box-value-icon'>
            <img src='/images/Icon-1.svg' className='icon-value'/>
            
           </div>
           <div className='value-des'>

           <h2>Relentless Value Creation</h2>
            <p>Committed to delivering consistent, compounding growth for investors through innovative and adaptive strategies.</p>
           </div>


          </div>
          <div className='about-value-prepotion'>
           <div className='box-value-icon'>
            <img src='/images/Icon-2.svg' className='icon-value'/>
            
           </div>
           <div className='value-des'>

           <h2>AI-Driven Excellence</h2>
            <p>Leveraging advanced artificial intelligence to identify opportunities, optimize strategies, and deliver unparalleled growth for investors.</p>
           </div>


          </div>

          <div className='about-value-prepotion'>
           <div className='box-value-icon'>
            <img src='/images/Icon-3.svg' className='icon-value'/>
            
           </div>
           <div className='value-des'>

           <h2>Investor-Centric Development</h2>
            <p>Ensuring every decision and strategy aligns with the goal of maximizing investor wealth and achieving exceptional results.</p>
           </div>


          </div>

         </div>

      </div>
    </div>
</div>
  </div>
  )
}

export default ValueProposition