import React from "react";
import "./StrategicFlexibility.css";

const StrategicFlexibility = () => {
  return (
    <div className="stragic-section-bg">
      <div className="container text-center my-5">
        <h2 className="mb-3 fs-30 ">STRATEGIC FLEXIBILITY</h2>
        <h1 className="display-4 mb-5 strategic-heading">
          DYNAMIC HEURISTIC &{" "}
          <span className="highlight about-us-m">AI-DRIVEN</span> INVESTING
        </h1>
        <div className="row justify-content-center mb-5">
          <div className="col-lg-6">
            <div className="div-box-container">
              <div className="box stragicFlex-box">
                <p>Driven by AI</p>
              </div>
              <div className="box stragicFlex-box">
                <p>Machine Learning</p>
              </div>
              <div className="box stragicFlex-box">
                <p>Heuristics</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <p
              className="lead"
              style={{ fontFamily: "Cera Pro", fontSize: "13px" }}
            >
              As a foundational principle for the establishment of Dovehouse
              Capital, which is a three strategy platform, we seek to generate
              attractive, uncorrelated benchmark beating returns through
              dynamically allocating capital on a timely and flexible basis
              across specific asset classes, strategies, geographies and market
              sectors. Driven by AI, machine learning and Heuristics, but always
              guided by the human touch. We strive for transparency for our
              investors, demystifying the realm of hedge funds past.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicFlexibility;
