import React from "react";
import { motion } from "framer-motion";
import img_leadership_bg from "assets/leadership_bg.svg";
import "./leadership.css";
import useWindowDimensions from "components/utils/useWindowDimensions";

const LeaderShip = () => {
  const { width } = useWindowDimensions();
  const isMobileView = width < 767;

  if (isMobileView) {
    return (
      <div className="leadership-container">
        <div className="leadership-image-div">
          <div
            className="leadership-bg flex items-center sm:bg-cover lg:bg-contain"
            style={{
              background: `url(${img_leadership_bg}) lightgray 50% / cover no-repeat`,
            }}
          >
            <div className="md:ml-20">
              <p className="leadership-heading">LEADERSHIP</p>
              <p className="leadership-title paragraph-gap">
                leading <br></br> <span className="text-white">investment</span>{" "}
                firm.
              </p>
              <p className="leadership-desc paragraph-gap">
                Our leadership team brings unique expertise and insightful track
                records from globally recognized companies across multiple
                industries. They have worked together for over three decades,
                including the formative years to build Dovehouse Capital into an
                industry-leading investment firm.
              </p>
              <a href="/leadership" className="investment-learn-more mb-0">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="leadership-container">
      <div className="leadership-image-div">
        <motion.div
          transition={{ ease: "easeOut", duration: 0.5 }}
          initial={{ opacity: 0, scale: 5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          className="leadership-bg flex items-center sm:bg-cover lg:bg-contain"
          style={{
            background: `url(${img_leadership_bg}) lightgray 50% / cover no-repeat`,
          }}
        >
          <div className="md:ml-20">
            <motion.div
              transition={{ ease: "easeOut", duration: 0.7, delay: 0.1 }}
              initial={{ opacity: 0, x: -40 }}
              whileInView={{ opacity: 1, x: 0 }}
            >
              <p className="leadership-heading">LEADERSHIP</p>
              <p className="leadership-title paragraph-gap">
                leading <br></br> <span className="text-white">investment</span>{" "}
                firm.
              </p>
            </motion.div>
            <motion.p
              transition={{ ease: "easeOut", duration: 0.7, delay: 0.2 }}
              initial={{ opacity: 0, y: -40 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="leadership-desc paragraph-gap"
            >
              Our leadership team brings unique expertise and successful track
              records from globally recognised financial institutions companies.
              They have worked together for over three decades, providing the
              foundation from which to build Dovehouse Capital into an
              industry-leading investment firm.
            </motion.p>
            <motion.div
              transition={{ ease: "easeOut", duration: 0.7, delay: 0.2 }}
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
            >
              <a
                href="/leadership"
                className="investment-learn-more mb-20"
                style={{ cursor: "pointer" }}
              >
                Learn more
              </a>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LeaderShip;
