import React from "react";
import imgInvestmentApproach from "assets/investmentapproach_img.svg";
import "./investment.css";

const Mobileinvestement = () => {
    return (
        <div className="investment-containers">
            <div className="investment-row">
                <div className="investment-col-left" style={{ backgroundImage: `url(${imgInvestmentApproach})` }}>
                    <p className="investment-bg-text">Investment <br /> Approach</p>
                </div>
                
                <div className="investment-col-right">
                  <div className="investment-title paragraph-gap">Strategic Investment for Optimal <span className="bg-cyan">Returns</span></div>
                    <div className="investment-description ">
                        <p>We combine a high level of expertise and experience across all functions and leverage well-defined and rigorous investment approaches built on key pillars.</p>
                        <p className="mb-4">Across our three core investment strategies we have one singular focus: delivering optimal and accessible risk-adjusted returns for our investors.</p>
                        <a href="/investment" className="investment-learn-more">Learn more</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mobileinvestement;
