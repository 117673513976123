// "use client";

// import { ReactComponent as Logo } from "assets/logo_original_colored.svg";
// import { ReactComponent as WhiteLogo } from "assets/logo_white.svg";
// import { ReactComponent as BlueLogo } from "assets/logo_original_dark.svg";

// import About from "./about/About";
// import Investment from "./investment/Investment";
// import Leadership from "./leadership/leadership";
// import Culture from "./culture/culture";

// import React, { useEffect, useRef, useState } from "react";
// import "./styles.css";
// import { motion, useSpring } from "framer-motion";
// import useWindowDimensions from "components/utils/useWindowDimensions";

// import img_about_bg from "assets/about_bg.svg";
// import img_homepage_bg from "assets/homepage_bg.png";
// import img_leadership_bg from "assets/leadership_bg.svg";
// import img_investmentapproach_img from "assets/investmentapproach_img.svg";
// import img_logo_original from "assets/logo_original.svg";
// import img_intro_bg from "assets/intro_bg.svg";
// import img_culture_img from "assets/culture_img.svg";
// import img_drawerLogo from "assets/drawerLogo.svg";
// // import logoGif from "assets/headerlogo.gif";
// import logoGif from "assets/newgif.png";
// import Mobileinvestement from "./investment/Mobileinvestement";

// export default function HomeMobile(props) {
//   const { height, width } = useWindowDimensions();
//   const isMobileView = width < 767;

//   const [currentSection, setCurrentSection] = useState(1);
//   const [showGifLogo, setShowGifLogo] = useState(true);
//   const [imagesLoaded, setImagesLoaded] = useState(false);
//   const [showWhiteLogo, setShowWhiteLogo] = useState(false);
//   const [scrollY, setScrollY] = useState(0);
//   const [showBlueLogo, setShowBlueLogo] = useState(false);

//   const spring = useSpring(0, {
//     stiffness: 100,
//     damping: 30,
//     restDelta: 0.001,
//   });

//   const imageUrls = [
//     img_investmentapproach_img,
//     img_about_bg,
//     img_homepage_bg,
//     img_investmentapproach_img,
//     img_leadership_bg,
//     img_investmentapproach_img,
//     img_logo_original,
//     img_intro_bg,
//     img_culture_img,
//     img_drawerLogo,
//   ];

//   useEffect(() => {
//     const handleScroll = () => {
//       setScrollY(window.scrollY);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setCurrentSection(3);
//     }, 2900); // 2000 milliseconds = 2 seconds

//     return () => clearTimeout(timer); // Cleanup the timer on component unmount
//   }, []);

//   useEffect(() => {
//     let images = [];
//     let loadedImages = 0;

//     const onImageLoad = () => {
//       loadedImages++;
//       if (loadedImages === imageUrls.length) {
//         setImagesLoaded(true);
//       }
//     };

//     imageUrls.forEach((url) => {
//       const img = new Image();
//       img.onload = onImageLoad;
//       img.src = url;
//       images.push(img);
//     });

//     return () => {
//       images.forEach((img) => (img.onload = null)); // Cleanup
//     };
//   }, []);

//   useEffect(() => {
//     if (currentSection === 1 || currentSection === 2) {
//       spring.set(0);
//     } else {
//       let progress = (currentSection - 2) / (homeRef.current.MAX_SECTIONS - 2);
//       spring.set(progress * height);
//     }
//   }, [currentSection, height, spring]);

//   const homeRef = useRef({
//     MIN_SECTIONS: 1,
//     MAX_SECTIONS: 6,
//     timeoutInstance: null,
//     homeContentDiv: {
//       visible: { opacity: 1 },
//       hidden: { opacity: 0 },
//     },
//     menu: {
//       visible: { opacity: 1, y: "-40vh" },
//       hidden: { opacity: 0, y: "-40vh" },
//     },
//   });
//   useEffect(() => {
//     // Logic to show GIF, white, or blue logo based on scroll and section
//     if (scrollY > 100) {
//       setShowGifLogo(true);
//       if (currentSection === 2) {
//         // Show white logo for section 2
//         setShowWhiteLogo(true);
//         setShowGifLogo(false); // Hide GIF when white logo is shown
//         setShowBlueLogo(false); // Hide blue logo for other sections
//       } else if (currentSection > 2) {
//         // Show blue logo for sections 3, 4, 6, and 7
//         setShowBlueLogo(true);
//         setShowGifLogo(false); // Hide GIF when blue logo is shown
//         setShowWhiteLogo(false); // Hide white logo when blue logo is shown
//       } else {
//         setShowWhiteLogo(false); // Show GIF logo for other sections
//         setShowBlueLogo(false); // Hide blue logo for other sections
//       }
//     } else {
//       // When scrollY is <= 100
//       if (currentSection === 2) {
//         setShowWhiteLogo(true); // Show white logo for section 2
//         setShowGifLogo(false);
//         setShowBlueLogo(false);
//       } else if (currentSection > 2) {
//         // Show blue logo for sections 3, 4, 6, and 7
//         setShowBlueLogo(true);
//         setShowGifLogo(false); // Hide GIF logo when blue logo is shown
//         setShowWhiteLogo(false); // Hide white logo when blue logo is shown
//       } else {
//         setShowGifLogo(true); // For other sections, show GIF
//         setShowWhiteLogo(false);
//         setShowBlueLogo(false); // Hide blue logo
//       }
//     }
//   }, [scrollY, currentSection]);

//   const handleNavigation = (direction) => {
//     setCurrentSection((prev) => {
//       const newSection =
//         direction > 0
//           ? Math.min(prev + 1, homeRef.current.MAX_SECTIONS)
//           : Math.max(prev - 1, homeRef.current.MIN_SECTIONS);

//       console.log("New Section:", newSection);

//       // Handle section-specific logic here
//       if (newSection === 2) {
//         setShowWhiteLogo(true); // Show white logo for section 2
//         setShowGifLogo(false);
//         setShowBlueLogo(false); // Hide blue logo
//         console.log("White logo shown for section 2");
//       } else if (newSection > 2 && newSection == 5) {
//         setShowBlueLogo(true); // Show blue logo for sections 3, 4, 6, and 7
//         setShowWhiteLogo(false); // Hide white logo
//         setShowGifLogo(false); // Hide GIF logo
//         console.log("Blue logo shown for sections 3, 4,,5, 6, and 7");
//       }

//       return newSection;
//     });
//   };

//   const handleWheelChange = (event) => {
//     if (currentSection === 4 || currentSection === 6) {
//       return;
//     }

//     if (homeRef.current.timeoutInstance) {
//       clearTimeout(homeRef.current.timeoutInstance);
//     }
//     homeRef.current.timeoutInstance = setTimeout(() => {
//       handleNavigation(event.deltaY > 0 ? 1 : -1);
//     }, 500);
//   };

//   const handleTouchEnd = (e) => {
//     let deltaY = e.changedTouches[0].clientY - homeRef.current.clientY;
//     handleWheelChange({ deltaY: -deltaY });
//   };

//   const handleTouchStart = (e) => {
//     homeRef.current.clientY = e.touches[0].clientY;
//   };

//   const LogoComponent = () => {
//     if (showGifLogo) {
//       return (
//         <img
//           src={logoGif}
//           alt="Dovehouse Capital Logo"
//           className="logo-gif"
//           style={{ cursor: "pointer" }}
//           id="logo"
//           onClick={() => {
//             setCurrentSection(3);
//           }}
//         />
//       );
//     } else if (showWhiteLogo) {
//       console.log("White logo shown");
//       return (
//         <WhiteLogo
//           onClick={() => setCurrentSection(1)}
//           id="logo"
//           style={{ cursor: "pointer" }}
//         />
//       );
//     } else if (showBlueLogo) {
//       console.log("Blue logo shown");
//       return (
//         <BlueLogo
//           //   onClick={() => setCurrentSection(currentSection === 2 ? 1 : 2)}
//           id="logo"
//           style={{ cursor: "pointer", width: "50%", top:'6px' }}
//         />
//       );
//     } else {
//       return (
//         <Logo
//           //   onClick={() => setCurrentSection(currentSection === 2 ? 1 : 2)}
//           id="logo"
//           style={{ cursor: "pointer", width: "50%" }}
//         />
//       );
//     }
//   };

//   if (!imagesLoaded) {
//     return (
//       <div className="flex justify-center w-full h-lvh items-center">
//         <div
//           style={{ color: "#3B3B3B" }}
//           className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]"
//           role="status"
//         >
//           <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
//             Loading...
//           </span>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <>
//       <motion.div
//         className="progress-bar"
//         transition={{ ease: "easeOut", duration: 2 }}
//         style={{ height: spring }}
//       />
//       <div
//         onTouchStart={handleTouchStart}
//         onTouchEnd={handleTouchEnd}
//         onWheel={handleWheelChange}
//         className={`home-container section-${currentSection}`}
//       >
//         <header className="header">
//           <div
//             className="inner-content"
//             onClick={() => {
//               props.toggleDrawer();
//               console.log("Clicked");
//             }}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="57"
//               height="21"
//               viewBox="0 0 57 21"
//               fill="none"
//             >
//               <rect width="57" height="1.09524" fill="#323131" />
//               <rect y="9.95312" width="57" height="1.09524" fill="#323131" />
//               <rect y="19.9043" width="57" height="1.09524" fill="#323131" />
//             </svg>
//           </div>
//         </header>
//         <main>
//           <section id="home">
//             <div className="bg-container-logo">
//               <div>
//                 <div className="background">
//                   <div className="image blend"></div>
//                 </div>

//                 <LogoComponent />
//               </div>
//             </div>
//           </section>

//           {isMobileView && currentSection >= 3 && (
//             <section
//               id="section-3"
//               className={`sec-content ${
//                 currentSection >= 3 ? "active-section" : ""
//               }`}
//             >
//               <div className="mobile-view-div">
//                 <div className="background-mobile-img flex flex-col justify-center items-center text-center">
//                   <div className="inner-content p-4">
//                     <h1 className="text-[36px] font-primary text-white leading-[1]">
//                       Investment Leader, Diverse <br />
//                       Strategies Worldwide
//                     </h1>
//                     <p
//                       className="text-[16px]  text-white mt-4"
//                       style={{ fontFamily: "Cera Pro" }}
//                     >
//                       We aim to democratize hedge fund access, offering
//                       transparent, AI-driven strategies for benchmark-beating
//                       returns.
//                     </p>
//                   </div>
//                 </div>
//                 <About></About>
//                 {/* <Investment navigate={() => {}}></Investment> */}
//                 <Mobileinvestement navigate= {() => {}} ></Mobileinvestement>
//                 <Leadership></Leadership>
//                 <Culture navigate={() => {}}></Culture>
//               </div>
//             </section>
//           )}
//         </main>
//       </div>
//     </>
//   );
// }


import { ReactComponent as Logo } from "assets/logo_original_colored.svg";
import { ReactComponent as BlueLogo } from "assets/logo_original_dark.svg";

import About from "./about/About";
import Investment from "./investment/Investment";
import Leadership from "./leadership/leadership";
import Culture from "./culture/culture";

import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import useWindowDimensions from "components/utils/useWindowDimensions";

import img_about_bg from "assets/about_bg.svg";
import img_homepage_bg from "assets/homepage_bg.png";
import img_leadership_bg from "assets/leadership_bg.svg";
import img_investmentapproach_img from "assets/investmentapproach_img.svg";
import img_logo_original from "assets/logo_original.svg";
import img_intro_bg from "assets/intro_bg.svg";
import img_culture_img from "assets/culture_img.svg";
import img_drawerLogo from "assets/drawerLogo.svg";
import logoGif from "assets/newgif.png";
import Mobileinvestement from "./investment/Mobileinvestement";

const Preloader = () => (
  <div className="flex justify-center w-full h-lvh items-center bg-loader-mobile">
   <div className="loader-container">
  <div className="loader-wrapper">
    <img src={logoGif} alt="Loading..." className="preloader-logo" />
    <div className="loader-spinner"></div>
  </div>
</div>



  </div>
);

export default function HomeMobile(props) {
  const { height, width } = useWindowDimensions();
  const isMobileView = width < 767;

  const [currentSection, setCurrentSection] = useState(3); // Start from section-3
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [showBlueLogo, setShowBlueLogo] = useState(false);
  const [progressHeight, setProgressHeight] = useState(0);

  const homeRef = useRef({
    MIN_SECTIONS: 3, // Set min section to 3
    MAX_SECTIONS: 6,
    timeoutInstance: null,
    clientY: 0,
  });

  const imageUrls = [
    img_investmentapproach_img,
    img_about_bg,
    img_homepage_bg,
    img_leadership_bg,
    img_logo_original,
    img_intro_bg,
    img_culture_img,
    img_drawerLogo,
  ];

  useEffect(() => {
    let images = [];
    let loadedImages = 0;

  const onImageLoad = () => {
    loadedImages++;
    if (loadedImages === imageUrls.length) {
      setTimeout(() => {
        setImagesLoaded(true);
      }, 4000); // Delay for 5 seconds
    }
  };

    imageUrls.forEach((url) => {
      const img = new Image();
      img.onload = onImageLoad;
      img.src = url;
      images.push(img);
    });

    return () => {
      images.forEach((img) => (img.onload = null));
    };
  }, []);

  useEffect(() => {
    if (currentSection === 3) {
      setProgressHeight(0);
    } else {
      let progress = (currentSection - 3) / (homeRef.current.MAX_SECTIONS - 3);
      setProgressHeight(progress * height);
    }
  }, [currentSection, height]);

  useEffect(() => {
    setShowBlueLogo(currentSection > 3);
  }, [scrollY, currentSection]);

  const handleNavigation = (direction) => {
    setCurrentSection((prev) => {
      const newSection =
        direction > 0
          ? Math.min(prev + 1, homeRef.current.MAX_SECTIONS)
          : Math.max(prev - 1, homeRef.current.MIN_SECTIONS);

      setShowBlueLogo(newSection > 3);
      return newSection;
    });
  };

  const handleWheelChange = (event) => {
    if (currentSection === 4 || currentSection === 6) {
      return;
    }

    if (homeRef.current.timeoutInstance) {
      clearTimeout(homeRef.current.timeoutInstance);
    }
    homeRef.current.timeoutInstance = setTimeout(() => {
      handleNavigation(event.deltaY > 0 ? 1 : -1);
    }, 500);
  };

  const handleTouchEnd = (e) => {
    let deltaY = e.changedTouches[0].clientY - homeRef.current.clientY;
    handleWheelChange({ deltaY: -deltaY });
  };

  const handleTouchStart = (e) => {
    homeRef.current.clientY = e.touches[0].clientY;
  };

  const LogoComponent = () =>
    showBlueLogo ? <BlueLogo id="logo" style={{ cursor: "pointer", width: "50%", top: "6px" }} /> : <Logo id="logo" style={{ cursor: "pointer", width: "50%" ,top:"5px" }} />;

  if (!imagesLoaded) return <Preloader />;

  return (
    <>
      <div
        className="progress-bar"
        style={{
          height: progressHeight,
          transition: "height 0.5s ease-out",
        }}
      />
      <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onWheel={handleWheelChange} className={`home-container section-${currentSection}`}>
        <header className="header">
          <div
            className="inner-content"
            onClick={() => {
              props.toggleDrawer();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="21" viewBox="0 0 57 21" fill="none">
              <rect width="57" height="1.09524" fill="#323131" />
              <rect y="9.95312" width="57" height="1.09524" fill="#323131" />
              <rect y="19.9043" width="57" height="1.09524" fill="#323131" />
            </svg>
            
          </div>
           <LogoComponent />
        </header>
        <main>
          {isMobileView && currentSection >= 3 && (
            <section id="section-3" className={`sec-content ${currentSection >= 3 ? "active-section" : ""}`}>
              <div className="mobile-view-div">
                <div className="background-mobile-img flex flex-col justify-center items-center text-center">
                  <div className="inner-content p-1">
                    <h1 className="text-[36px] font-primary text-white leading-[1]">
                      Investment Leader, Diverse <br />
                      Strategies Worldwide
                    </h1>
                    <p className="text-[16px] text-white mt-4" style={{ fontFamily: "Cera Pro" }}>
                      We aim to democratize hedge fund access, offering transparent, AI-driven strategies for benchmark-beating returns.
                    </p>
                  </div>
                </div>
                <About />
                <Mobileinvestement />
                <Leadership />
                <Culture />
              </div>
            </section>
          )}
        </main>
      </div>
    </>
  );
}
