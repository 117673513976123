


"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { 
  Tabs, 
  Tab, 
  Box, 
  Pagination, 
  PaginationItem 
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { BASE_URL } from "APIS/constatnts"

const Blogs = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [newsData, setNewsData] = useState([])
  const [categories, setCategories] = useState([])
  const [categoryIds, setCategoryIds] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const navigate = useNavigate()

const createSlug = (title) => {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-|-$/g, '')
        .substring(0, 50); // Limit slug length
};

  

  const BLOGS_PER_PAGE = 6

  const extractUniqueCategories = (newsItems) => {
    const categoriesMap = new Map()
    
    newsItems.forEach(item => {
      if (item.category_name && !categoriesMap.has(item.category_name)) {
        categoriesMap.set(item.category_name, item.category_id)
      }
    })
    
    return {
      categories: Array.from(categoriesMap.keys()),
      categoryIds: Array.from(categoriesMap.values())
    }
  }



  const fetchNews = async () => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await axios.get(`${BASE_URL}news-list`, {
        validateStatus: function (status) {
          return status < 500; // Resolve for all responses < 500 (including 404)
        }
      })
      
      console.log("Initial News Response:", response.data);
      
      // Check if the response has a "message" field with "No news found"
      if (response.data?.message === "No news found") {
        setError("No news found")
        setIsLoading(false)
        return
      }
      
      // Check for 404 status in HTTP or data
      if (response.status === 404 || response.data?.status === 404) {
        setError("No news data available")
        setIsLoading(false)
        return
      }
      
      // In the fetchNews function
      const rawData = response.data?.data?.data || [];      
      if (rawData.length === 0) {
        setError("No news data available")
        setIsLoading(false)
        return
      }

      const { categories, categoryIds } = extractUniqueCategories(rawData)
      
      setCategories(categories)
      setCategoryIds(categoryIds)

      // Fetch news for first category
      if (categoryIds.length > 0) {
        await fetchCategoryNews(categoryIds[0], 1)
      }
    } catch (error) {
      console.error("Error fetching news:", error)
      setError("Failed to fetch news. Please try again later.")
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCategoryNews = async (categoryId, page) => {
    setIsLoading(true);
    setError(null);
    setNewsData([]); // Clear old data to prevent duplicate items
  
    try {
      // Use axios with validateStatus option to prevent throwing on 404
      const response = await axios.get(`${BASE_URL}news-list?category_id=${categoryId}&page=${page}&per_page=${BLOGS_PER_PAGE}`, {
        validateStatus: function (status) {
          return status < 500; // Resolve for all responses < 500 (including 404)
        }
      });
      
      console.log("Category News Response:", response.data);
      
      // First, specifically check for the "No news found" message
      if (response.data?.message === "No news found") {
        console.log("No news found message detected");
        setError("No news found");
        setNewsData([]);
        setTotalPages(1);
        return;
      }
      
      // Then check for empty data array even with status 200
      if (!response.data?.data?.data || response.data?.data?.data.length === 0) {
        console.log("Empty data array detected");
        setError("No news found");
        setNewsData([]);
        setTotalPages(1);
        return;
      }
      
      // Check for 404 status
      if (response.status === 404 || response.data?.status === 404) {
        console.log("404 status detected");
        setError("No news found");
        setNewsData([]);
        setTotalPages(1);
        return;
      }
      
      // Process normal response
      const newsDataResponse = response.data?.data?.data || [];
      setNewsData(newsDataResponse);
      setTotalPages(response.data?.data?.last_page || 1);
      setCurrentPage(page);
      
      // If we still got an empty array but no explicit error, also set error state
      if (newsDataResponse.length === 0) {
        setError("No news found");
      } else {
        setError(null);
      }
    } catch (error) {
      console.error("Error in fetchCategoryNews:", error);
      setError("Failed to fetch category news.");
      setNewsData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = async (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1); // Reset to first page
  
    const selectedCategoryId = categoryIds[newValue];
  
    if (selectedCategoryId) {
      await fetchCategoryNews(selectedCategoryId, 1);
    }
  };
  
  const handlePageChange = (event, newPage) => {
    console.log(`Changing to page ${newPage}`);
    setCurrentPage(newPage);
    const selectedCategoryId = categoryIds[activeTab];
  
    if (selectedCategoryId) {
      fetchCategoryNews(selectedCategoryId, newPage);
    }
  };
  
  useEffect(() => {
    fetchNews()
  }, [])

  return (
    <>
      <div className="section-titles">
        <motion.h2
          style={{ paddingBottom: "20px", letterSpacing: "-2.5px" }}
          className="text-4xl font-serif"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          News & insights
        </motion.h2>
      </div>
      <div className="px-4 mx-auto main-section-news">
        {categories.length > 0 ? (
          <>
            <Box sx={{ borderBottom: 0, borderColor: "divider", mb: 4 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                className="tabs-blogs"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontSize: "1rem",
                    fontFamily: "Cera Pro",
                    fontWeight: 500,
                  },
                  "& .Mui-selected": {
                    color: "#0ea5e9 !important",
                    borderBottom: "1px solid #33B9EA;",
                    fontFamily: "Cera Pro",
                    fontWeight: 600,
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#0ea5e9",
                    fontFamily: "Cera Pro",
                  },
                }}
              >
                {categories.map((category, index) => (
                  <Tab
                    key={index}
                    className="tabs-news"
                    label={category}
                  />
                ))}
              </Tabs>
            </Box>

            {categories.map((category, index) => (
              <div key={index} hidden={activeTab !== index}>
                {isLoading ? (
                  <div className="col-span-3 text-center py-8">
                    <p>Loading articles...</p>
                  </div>
                ) : error === "No news found" || !newsData || newsData.length === 0 ? (
                  <div className="text-center py-8">
                    <h2 className="section-title text-2xl font-semibold text-gray-500 coming-soon">
                      COMING SOON
                    </h2>
                    {/* For debugging - can be removed in production */}
                    {process.env.NODE_ENV === 'development' && (
                      <p className="text-sm text-gray-400 mt-2">Error: {error}</p>
                    )}
                  </div>
                ) : (
                  <>
               <motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.6 }}
  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 news-list-section"
>
  {newsData.map((article) => {
    const articleSlug = createSlug(article.title); // Generate slug from title

    return (
      <div
        key={article.id}
        className="bg-white overflow-hidden shadow-md card-blogs cursor-pointer"
        onClick={() => navigate(`/${articleSlug}`)}
      >
        <div className="relative h-64">
          <img
            src={article.image}
            alt={article.title}
            className="absolute inset-0 w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div className="p-6">
          <time className="text-sky-500 text-sm">{article.news_date}</time>
          <h3
            className="blog-heading font-serif text-xl mt-2 mb-3 cursor-pointer hover:text-sky-500"
            onClick={() => navigate(`/news-insights-detail/${articleSlug}`)}
          >
            {article.title}
          </h3>
          <div
            className="text-gray-600 mb-4 line-clamp-3"
            dangerouslySetInnerHTML={{ __html: article.short_description }}
          />
          <button
            className="btn-blogs text-sky-500 border border-sky-500 px-4 py-2 hover:bg-sky-100 transition-colors"
            onClick={() => navigate(`/${articleSlug}`)}
          >
            READ MORE
          </button>
        </div>
      </div>
    );
  })}
</motion.div>


                    {/* Pagination */}
                    {newsData.length > 0 && (
                      <div className="flex justify-center mt-8 mb-8">
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        ) : error === "No news found" ? (
          <div className="text-center py-8">
            <h2 className=" text-2xl font-semibold text-gray-500 coming-soon text-center">
              COMING SOON
            </h2>
           
          </div>
        ) : isLoading ? (
          <div className="col-span-3 text-center py-8">
            <p>Loading articles...</p>
          </div>
        ) : (
          <div className="text-center py-8">
            <h2 className="section-title text-2xl font-semibold text-gray-500 text-center">
              COMING SOON
            </h2>
           
          </div>
        )}
      </div>
    </>
  )
}

export default Blogs
