import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion
import "./AboutUs.css";
import Aboutimgurl from "../../assets/about-1.png";
import InvestmentMastery from "./inventorymaster/InvestmentMastery";
import Prosperity from "./Prosperity/Prosperity";
import StrategicFlexibility from "./StrategicFlexibility/StrategicFlexibility";
import Corevalue from "./Prosperity/Corevalue";

const About = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if screen width is mobile-sized
    const checkMobile = () => {
      return window.innerWidth <= 768;
    };

    // Set initial state
    setIsMobile(checkMobile());

    // Add resize listener
    const handleResize = () => setIsMobile(checkMobile());
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Render different content based on screen size
  const renderContent = () => {
    if (isMobile) {
      // Static version for mobile
      return (
        <div className="about-us-content">
          <h2 className="section-title">ABOUT US</h2>
          <h1 className="main-title main-title-about">
            Dovehouse CAPITAL IS A <br className="br-none" />
            GLOBAL <span className="highlight about-us-m">INVESTMENT</span>
            <br />
            MANAGEMENT FIRM
          </h1>
          <p className="subtitle">
            With offices in Abu Dhabi & London. We were established
            <br className="br-none" />
            in 2024, by Martin Rasmussen & Claus Rosenberg Gotthard.
          </p>
        </div>
      );
    } else {
      // Animated version for desktop
      return (
        <div className="about-us-content">
          <motion.h2
            className="section-title"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            ABOUT US
          </motion.h2>
          <motion.h1
            className="main-title main-title-about"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            Dovehouse CAPITAL IS A <br className="br-none" />
            GLOBAL <span className="highlight about-us-m">INVESTMENT</span>
            <br />
            MANAGEMENT FIRM
          </motion.h1>
          <p className="subtitle">
            With offices in Abu Dhabi & London. We were established
            <br />
            in 2024, by Martin Rasmussen & Claus Rosenberg Gotthard.
          </p>
        </div>
      );
    }
  };

  return (
    <div className="about-contents">
      <section className="about-us">
        {renderContent()}
      </section>

      <div className="image-container">
        <img
          src={Aboutimgurl}
          alt="Team meeting"
          className="img-fluid"
          data-aos={isMobile ? "" : "fade-right"}
          data-aos-duration={isMobile ? "" : "300"}
          data-aos-easing={isMobile ? "" : "ease-in-sine"}
        />
      </div>

      <InvestmentMastery />
      <Corevalue />
      <Prosperity />
      <StrategicFlexibility />
    </div>
  );
};

export default About;